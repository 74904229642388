<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('bank.bankList') }}</span>   
        <CommonAdd v-if="accessRight.includes('create')" :name="$t('bank.bank')" pathName="addBank" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <BankListTable :listSkeleton.sync="listSkeleton" @redirectOnEditPage="redirectOnEditPage" @deleteItem="deleteItem" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import BankListTable from './BankListTable';
import CommonAdd from '@/components/CommonAdd';

export default {
  name: 'Bank',
  components: {
    BankListTable,
    CommonAdd
  },
  computed: {
    ...mapGetters([
      'accessRight'
    ]),
  },
  data() {
    return {
      listSkeleton: false,
    }
  },
  methods: {
    redirectOnEditPage(item) {
      const path = 'bank/editBank/' + item.id
      window.open(path, '_blank')
    },
    async deleteItem(item) {
      await this.$store.dispatch('bank/DeleteBankItemById', item.id);
    }
  }
}
</script>
